<template>
  <section class="main-body login">
    <v-card :class="'login__box'">
      <v-card-title :class="'login__box-gc'"><img src="../assets/gamechanger-logo-purple.svg" alt="Gamechanger"/></v-card-title>

      <v-alert :value="true"  v-if="error != null" type="error" >{{ error.message }}</v-alert>
      <v-alert :value="true"  v-if="success != null" type="success" >{{ success.message }}</v-alert>

    <v-form ref="form" v-model="valid" @submit="resetPassword">
      <v-text-field :class="'login__box-field'" outline v-model="username" :rules="usernameRules" label="Email *"  type="email" autocomplete="email" required ></v-text-field>


      <v-layout row align-center justify-center mx-1>
        <v-btn
          color="accent"
          :disabled="!valid"
          :class="'button'"
          depressed
          type="submit"
        >
          Send Reset Code
        </v-btn>
      </v-layout>

    </v-form>
  </v-card>
  </section>
</template>


<script>
export default {
  name: "PasswordReset",
  data() {

    return {
      valid: true,
      usernameRules: [
        v => !!v || "Email address is required",
        v => /.+@.+/.test(v) || "Email must be valid"
      ],
      username: "",
      pass: "",
      newPassword: "",
      error: null,
      success: null,
      loading: false
    }
  },
  mounted() {
    console.log("MOUNTED PASSWORD FORGOT")
  },
  methods: {
    resetPassword(e) {
      e.preventDefault()

      this.loading = true
      var email = this.username

        this.$forgotPassword(email).subscribe((data) => {
            console.log("forgot password request success"+ data);
            this.$router.push("/login-reset-password");
        }, 
        (err) => {
          console.log(err);
          this.error = err;
        });

    }
  }
}
</script>
