<template>
<section class="main-body">
  <h1 class="subheading">Reset Password</h1>
  <p>Your password has been reset, please use the code sent to your email address to set a new password.</p>

  <v-card :class="'login__box'">
    <v-card-title :class="'login__box-gc'"><img src="../assets/gamechanger-logo-purple.svg" alt="Gamechanger" /></v-card-title>

    <v-alert :value="true" v-if="error != null" type="error">{{ error.message }}</v-alert>

    <v-form ref="form" v-model="valid" @submit="login">

      <v-text-field :class="'login__box-field'" outline v-model="username" :rules="usernameRules" label="Email Address" autocomplete="email" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="code" :rules="codeRules" label="Code" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="pass" :rules="passRules" label="Password" type="password" required></v-text-field>

      <v-layout row align-center justify-center mx-1>
        <v-btn color="accent" :disabled="!valid" :class="'button'" depressed type="submit">
          Set Password
        </v-btn>
      </v-layout>

    </v-form>

  </v-card>
</section>
</template>


<script>
export default {
  name: "LoginResetPassword",
  data() {

    return {
      valid: true,
      passRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Password must be at least 8 characters",
        v => /.*[a-z].*/.test(v) || "Password must contain at least one lower case letter",
        v => /.*[A-Z].*/.test(v) || "Password must contain at least one upper case letter",
        v => /\d/.test(v) || "Password must contain at least one number",
        v => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(v) || "Password must contain at least one special character (e.g. ^ $ * . ! @ # % &)"
      ],
      usernameRules: [
        v => !!v || "Email address is required",
        v => /.+@.+/.test(v) || "Email must be valid"
      ],
      codeRules: [
        v => !!v || "Verification code is required",
        v => /\S/.test(v) || "Verification code is must be the correct format"
      ],
      username: "",
      code: "",
      pass: "",
      error: null,
      loading: false,
      defaultSettings: null
    }
  },

  created()  {
    this.defaultSettings = require("../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    if (this.defaultSettings.default.customPassRules) {
      const customPassRules = this.defaultSettings.default.customPassRules
      this.passRules = [
        value => !!value || "Password is required",
        value => (value && value.length >= (customPassRules.length || 8)) || `Password must be at least ${(customPassRules.length || 8)} characters`,
        value => /.*[A-Z].*/.test(value) || "Password must contain at least one upper case letter",
        value => /\d/.test(value) || "Password must contain at least one number",
        value => /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value) || "Password must contain at least one special character (e.g. ^ $ * . ! @ # % &)"
      ]
      if (customPassRules && customPassRules.lowercase) {
        this.passRules.push(value => /.*[a-z].*/.test(value) || "Password must contain at least one lower case letter")
      }
    }
  },

  mounted() {
    console.log("Login component loaded")
  },
  methods: {
    login(e) {
      e.preventDefault()
      this.loading = true
      var email = this.username
      var code = this.code
      var password = this.pass
      const vm = this

      this.$confirmPassword(email, code, password).subscribe((data) => {
          console.log("reset password success success");
          window.alert("Password reset successfully ")
          setTimeout(() => {
            vm.$router.push("/login")
          }, 1000);
        },
        (err) => {

          this.error = err;
          if (this.error.message == "Invalid code provided, please request a code again.") {
            this.error.message = "Please ensure that you enter the correct verification code and the correct email address that you registered with."
          }

        });

    }
  }
}
</script>
