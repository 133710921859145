<template>
<section class="main-body">
  <v-card :class="'login__box'">
    <v-card-title :class="'login__box-gc'"><img src="../assets/gamechanger-logo-purple.svg" alt="Gamechanger" /></v-card-title>

    <h1 class="subheading">Set New Password</h1>
    <v-alert :value="true" v-if="error != null" type="error">{{ error.message }}</v-alert>
    <v-alert :value="true" v-if="success != null" type="success">{{ success.message }}</v-alert>
    <v-form ref="form" v-model="valid" @submit="login">

      <v-text-field :class="'login__box-field'" outline v-model="username" :rules="usernameRules" label="Email *" type="email" autocomplete="email" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="given_name" :rules="nameRules" label="First Name*" type="text" autocomplete="given-name" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="family_name" :rules="nameRules" label="Second Name*" type="text" autocomplete="family-name" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="pass" :rules="passRules" label="Existing Password *" type="password" required></v-text-field>

      <v-text-field :class="'login__box-field'" outline v-model="newPassword" :rules="passRules" label="New Password *" type="password" required></v-text-field>

      <v-layout row align-center mx-1>
        <v-spacer />
        <v-btn color="accent" :disabled="!valid" :class="'button'" depressed type="submit">
          Login
        </v-btn>
      </v-layout>

    </v-form>
  </v-card>
</section>
</template>


<script>
export default {
  name: "Login",
  data() {

    return {
      valid: true,
      passRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Remember, your password will have been at least 8 characters, contained a special character and contained both an upper case and a lower case letter"
      ],
      usernameRules: [
        v => !!v || "Email address is required",
        v => /.+@.+/.test(v) || "Email must be valid"
      ],
      nameRules: [
        v => !!v || "Name is required"
      ],
      username: "",
      given_name: "",
      family_name: "",
      pass: "",
      newPassword: "",
      error: null,
      success: null,
      loading: false,
      defaultSettings: null,
    }
  },

  created()  {
    this.defaultSettings = require("../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    if (this.defaultSettings.default.customPassRules) {
      const customPassRules = this.defaultSettings.default.customPassRules
      this.passRules = [
        v => !!v || "Password is required",
        v => (v && v.length >= (customPassRules.length || 8)) || `Remember, your password will have been at least ${customPassRules.length || 8} characters, contained a special character and ${customPassRules.lowercase ? "contained both an upper case and a lower case letter" : "an upper case letter"}`
      ]
    }
  },

  mounted() {
    console.log("Login component loaded ")
    if (window.location.href.includes("code=true")) { //if the user is referred from a partner site
      localStorage.referrer = document.referrer
      this.success = {
        message: "Your account has been authenticated, please login below."
      }
    }

  },
  methods: {
    login(e) {
      e.preventDefault()

      this.loading = true
      var email = this.username
      var password = this.pass
      var newPassword = this.newPassword
      var addAttributes = {
        given_name: this.given_name,
        family_name: this.family_name
      }

      this.$authenticateUser(email, password, newPassword, addAttributes).subscribe((data) => {
          console.log("login success")
          // mobile app event trigger
          this.$router.push("/")

        },
        (err) => {
          console.log(err);
          this.error = err;
        });

    }
  }
}
</script>
