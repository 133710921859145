<template>
  <section class="global-settings">
    <v-tabs v-model="active">

      <v-tab :ripple="{ class: 'accent--text' }">My Details</v-tab>
      <v-tab-item px-0 class="main-body">
        <ProfileEdit v-if="dataLoaded" :data="userData"  @update="updateSeoData($event)"/>
      </v-tab-item>

      <v-tab :ripple="{ class: 'accent--text' }">Change Password</v-tab>
      <v-tab-item px-0 class="main-body">
        <ProfilePassword />
      </v-tab-item>

    </v-tabs>
  </section>
</template>

<script>
// import PartnerLogos from '@/components/GlobalSettings/PartnerLogos'
import ProfileEdit from "@/components/Profile/ProfileEdit"
import ProfilePassword from "@/components/Profile/ProfilePassword"


export default {
  name: "GlobalSettings",

  components: {
    ProfileEdit,
    ProfilePassword
  },

  data: () => ({
    active: null,
    dataLoaded: false,
    status: "saved",
    errorMessage: "",
    userData: {
      firstName: "",
      lastName: "",
      email: ""
    }
  }),

  mounted() {
    console.log("Edit page mounted");
    this.getUser();
  },

  methods: {
    getUser() {
      const vm = this
      this.$getCurrentUserAttributes().subscribe((userDataLoaded) => {
        console.log("User data", userDataLoaded)
        vm.loggedIn = true;
        vm.userData.firstName = userDataLoaded.given_name
        vm.userData.lastName = userDataLoaded.family_name
        vm.userData.email = userDataLoaded.email
        vm.dataLoaded = true
      }, 
      (err)=> {
        console.log("Access Check", err.message)
      });
    }
  }
}
</script>
