<template>
  <section class="tab-buffer">
    <v-flex>
      <p class="warning-text">{{ passRules }}</p>
      <v-alert :value="success" color="success" transition="scale-transition" > {{success}} </v-alert>
      <v-alert :value="error" color="error"  transition="scale-transition" > {{error}}</v-alert>
      <h2 class="subheading">Set New Password</h2>
      <div class="player__panel">
        <v-flex align-center class="player__panel-details">
          <v-layout>
            <div class="flex sm12 md5">
              <label class="label" for="meta-title">Existing Password</label>
              <v-text-field id="existingPassword" v-model="existingPassword" solo flat required type="password"></v-text-field>
            </div>
            
          </v-layout>

          <v-layout>
            <div class="flex sm12 md5">
              <label class="label" for="meta-title">New Password</label>
              <v-text-field id="newpassword" v-model="newPassword" solo flat required type="password"></v-text-field>
            </div>
            <div class="flex sm12 md5 offset-md1">
              <label class="label" for="meta-keywords">Confirm New Password</label>
              <v-text-field  id="newpasswordConfirm" v-model="newPasswordConfirm" solo flat required type="password"></v-text-field>
            </div>

          </v-layout>

          <v-btn  color="#5EA77E" dark  v-on:click="updateUserPassword()" >Set Password</v-btn>

        </v-flex>
      </div>
    </v-flex>
  </section>
</template>

<script>

export default {
  name: "ProfilePassword",

  components: {
    
  },

  props: {
    
  },

  data: function () {
    return {
      existingPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
      success: null,
      error: null,
      passRules: null,
    }
  },

  created() {
    this.defaultSettings = require("../../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    let customPassRules
    if (this.defaultSettings.default.customPassRules) {
      customPassRules = this.defaultSettings.default.customPassRules
    }
    this.passRules = `Your password must be at least ${customPassRules ? customPassRules.length : 8} characters, contain a special character and ${customPassRules.lowercase ? "contain both an upper case and a lower case letter" : "an upper case letter"}`
  },

  methods: {
    updateUserPassword() {
      this.success = null
      this.error = null
      console.log("update password")
       
      var vm = this
      if(this.newPassword == this.newPasswordConfirm){
        this.$changePassword(this.existingPassword, this.newPassword).subscribe((response) => {
          console.log("update successful")
          vm.success = "Your password has been updated"
          setTimeout(() => {
            this.$signOut()
            this.$router.push("/login")
          }, 1000);
          console.log(response)
        }, 
        (err)=> {
          console.log("update not successful", err)
           vm.error = "Unable to update your password"
        });    
      }else{
        console.log("old and new password must match")
        vm.error = "Your new password and confirmation must match"
      }
        
           
    }
  }
}
</script>

<style lang="scss" scoped>
.player {
    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;
        &-details {
            flex-direction: column;
            @media (min-width: 960px) {
                flex-direction: row;
            }
        }
        &-img {
            img {
                width: 100%;
            }
        }
        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.warning-text {
    background-color: #f4f6d2;
    padding: 8px 16px;
    border: 1px solid #d6d8b0;
    border-radius: 8px;
    margin-bottom: 16px;
  }
</style>
