<template>
    <section class="tab-buffer">



        <v-flex>
            <v-alert
                :value="success"
                color="success"
                transition="scale-transition"
            > {{ success }} </v-alert>
            <v-alert
                :value="error"
                color="error"
                transition="scale-transition"
            > {{ error }}</v-alert>
            <h2 class="subheading">Access Rights</h2>
            <p>You have access rights to the following resources:</p>
            <div class="player__panel">
                <v-flex
                    align-center
                    class="player__panel-details"
                    v-if="userGroups"
                >
                    <ul>
                        <li
                            v-for="group in userGroups"
                            :key="group"
                        >{{ group }}</li>
                    </ul>

                </v-flex>
            </div>

            <h2 class="subheading">Details</h2>
            <div class="player__panel">
                <v-flex
                    align-center
                    class="player__panel-details"
                >
                    <v-layout>
                        <div class="flex sm12 md5">
                            <label
                                class="label"
                                for="meta-title"
                            >Email</label>
                            <v-text-field
                                id="email"
                                v-model="updatedData.email"
                                solo
                                readonly
                                flat
                                required
                            ></v-text-field>
                        </div>
                        <div class="flex sm12 md5 offset-md2">

                        </div>
                    </v-layout>

                    <v-layout>
                        <div class="flex sm12 md5">
                            <label
                                class="label"
                                for="meta-title"
                            >First Name</label>
                            <v-text-field
                                id="firstName"
                                v-model="updatedData.firstName"
                                solo
                                flat
                                required
                            ></v-text-field>
                        </div>
                        <div class="flex sm12 md5 offset-md1">
                            <label
                                class="label"
                                for="meta-keywords"
                            >Last Name</label>
                            <v-text-field
                                id="lastName"
                                v-model="updatedData.lastName"
                                solo
                                flat
                                required
                            ></v-text-field>
                        </div>
                    </v-layout>

                    <v-btn
                        color="#5EA77E"
                        dark
                        v-on:click="updateUser()"
                    >Save</v-btn>

                </v-flex>
            </div>

            <h2
                v-if="modules.default.MFA"
                class="subheading"
            >Multi-Factor Authentication</h2>
            <div
                v-if="modules.default.MFA"
                class="player__panel"
            >

                <p
                    v-if="mfaError"
                    class="mfa-error"
                >An error has occurred. Please try again or contact Urban Zoo for help</p>
                <p v-else-if="loading">Loading...</p>
                <template v-else-if="isMfaEnabled">
                    <p>Multi-Factor Authentication is enabled for this user.
                        <span v-if="userMfaSetting && userMfaSetting.length">Type: {{ this.userMfaSetting.join(", ").replaceAll("_", " ")
                        }}</span>
                    </p>
                </template>
                <p v-else-if="!setupKey && !isMfaEnabled">Multi-Factor Authentication is currently disabled for this user</p>
                <div v-else-if="setupKey">
                    <v-layout class="flex-container">
                        <div class="column-item">
                            <p>Setup Key: </p>
                            <p class="setup-key"><i>{{ setupKey }}</i></p>
                            <qrcode-vue
                                :value="`otpauth://totp/${this.$store.state.user.email}?secret=${setupKey}&issuer=gamechanger-${club}`"
                                :size="100"
                                level="H"
                            />
                        </div>
                        <div class="column-item">
                            <label
                                class="label"
                                for="auth-code"
                            >Please connect your authenticator app using the provided setup key or QR code and enter the app's code
                                below</label>
                            <div>
                                <v-text-field
                                    id="auth-code"
                                    v-model="authCode"
                                    solo
                                    flat
                                    required
                                ></v-text-field>
                            </div>
                            <v-btn
                                color="accent"
                                v-on:click="connectApp()"
                            >Connect App</v-btn>
                            <p
                                v-if="mfaErrorMsg"
                                class="mfa-error"
                            > {{ mfaErrorMsg }}</p>
                        </div>
                    </v-layout>
                </div>
                <v-btn
                    v-if="!isMfaEnabled"
                    class="initialise-button"
                    v-on:click="initialiseMfa()"
                >Initialise New MFA</v-btn>
                <v-btn
                    v-if="isMfaEnabled"
                    class="initialise-button"
                    color="error"
                    v-on:click="requestDisableMfaConfirmation()"
                >Disable MFA</v-btn>
            </div>
        </v-flex>

    </section>
</template>

<script>
import axios from "axios"
import QrcodeVue from "qrcode.vue"

export default {
    name: "ProfileEdit",

    components: {
        QrcodeVue
    },

    props: {
        data: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            modules: null,
            isMfaEnabled: false,
            userMfaSetting: null,
            loading: true,
            mfaError: false,
            mfaErrorMsg: "",
            club: process.env.VUE_APP_CLUB_ID,
            api: `${process.env.VUE_APP_ADMINUSERSAPI}`,
            adminApi: `${process.env.VUE_APP_BASEURL}/adminusers`,
            updatedData: {},
            userGroups: null,
            success: null,
            error: null,
            setupKey: false,
            authCode: null,
        }
    },

    created() {
        this.modules = require("../../config/features/" + process.env.VUE_APP_CLUB_ID + "/modules.js")

        if (this.$store.state.user.email) {
            this.getUserMfaStatus()
        } else {
            const that = this

            this.$getCurrentUserAttributes().subscribe((userData) => {
                //check if the user has completed their registration (mostly this will be Social Media Registrations, post linking of their account)
                console.log("Access Check", "checking registration is complete")
                console.log("User data", userData)
                this.$store.commit("saveUserDetails", {
                    firstName: userData.given_name,
                    lastName: userData.family_name,
                    email: userData.email
                });
                that.getUserMfaStatus()
            })
        }
    },

    mounted() {
        console.log("Edit Profile mounted");
        this.updatedData = {
            firstName: this.data.firstName,
            lastName: this.data.lastName,
            email: this.data.email
        }
    },

    methods: {
        getUserGroups() {
            var vm = this
            this.$getCurrentUserGroups().subscribe((userGroups) => {
                console.log("getting user groups")
                console.log(userGroups)
                vm.userGroups = userGroups
                this.loading = false
            },
                (err) => {
                    console.log("getting user groups not successful", err)
                });
        },

        updateUser() {
            this.success = null
            this.error = null
            console.log("updating user profile")
            var firstName = this.updatedData.firstName
            var lastName = this.updatedData.lastName

            var attributesList = {
                "given_name": firstName,
                "family_name": lastName
            }
            var vm = this
            this.$updateAttributes(attributesList).subscribe((response) => {
                console.log("update successful")
                vm.success = "Your details have been updated successfully"
                console.log(response)
            },
                (err) => {
                    vm.error = "Unable to update your profile details"
                    console.log("update not successful", err)
                });
        },

        initialiseMfa() {
            this.loading = true
            this.mfaError = false
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                this.$getSession().subscribe((userSession) => {
                    axios({
                        data: {
                            attributes: {
                                accessToken: userSession.accessToken.jwtToken,
                            }
                        },
                        method: "POST",
                        url: `${this.api}/associate`,
                        headers: { "Authorization": jwt }
                    })
                        .then(response => {
                            console.log("MFA initialised", response)
                            this.loading = false
                            this.setupKey = response.data.data.attributes.secretCode
                        })
                        .catch(e => {
                            this.loading = false
                            this.mfaError = true
                            console.error("Problem initialising MFA", e);
                        });
                })
            })
        },

        connectApp() {
            this.loading = true
            this.mfaErrorMsg = ""
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                this.$getSession().subscribe((userSession) => {
                    axios({
                        data: {
                            attributes: {
                                accessToken: userSession.accessToken.jwtToken,
                                userCode: this.authCode,
                            },
                        },
                        method: "POST",
                        url: `${this.api}/verify`,
                        headers: { "Authorization": jwt }
                    })
                        .then(response => {
                            this.loading = false
                            if (response.data.data.attributes.status == "SUCCESS") {
                                console.log("Authentication app connection established")
                                this.setUserTotpPreference(true)
                            } else {
                                this.mfaErrorMsg = "Connection failed, please try again"
                            }
                        })
                        .catch(e => {
                            this.loading = false
                            this.mfaErrorMsg = "Connection failed, please try again"
                            console.error("Problem connecting authentication app", e);
                        });
                })
            })
        },

        requestDisableMfaConfirmation() {
            const answer = window.confirm("Do you really want to disable Multi-Factor Authentication?")
            if (answer) {
                this.setUserTotpPreference(false)
            }
        },

        setUserTotpPreference(totpPreference) {
            this.loading = true
            this.mfaErrorMsg = ""
            this.error = false
            console.log("inside totp")

            const data = {
                firstName: this.$store.state.user.firstName,
                lastName: this.$store.state.user.lastName,
                username: this.$store.state.user.email,
                email: this.$store.state.user.email,
                associatedUserGroups: this.userGroups,
                totpMFAEnabled: totpPreference
            }
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "POST",
                    url: `${this.adminApi}/${this.$store.state.user.email}`,
                    headers: { "Authorization": jwt },
                    data
                })
                    .then(response => {
                        console.log("totp response", response)
                        this.loading = false
                        if (response.data.success === true && totpPreference == true) {
                            this.isMfaEnabled = true
                        } else if (totpPreference == false) {
                            this.isMfaEnabled = false
                            this.setupKey = null
                        } else {
                            this.mfaError = true
                        }
                    })
                    .catch(e => {
                        this.loading = false
                        this.mfaError = true
                        console.error("Problem adding mfa", e);
                    })
            })
        },

        getUserMfaStatus() {
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.adminApi}/${this.$store.state.user.email}`,
                    headers: { "Authorization": jwt }
                })
                    .then(response => {
                        console.log("retrieved user:", response);
                        const user = JSON.parse(response.data.body)
                        if (user.UserMFASettingList && user.UserMFASettingList.length) {
                            this.userMfaSetting = user.UserMFASettingList
                            this.isMfaEnabled = true
                        }
                    })
                    .catch(e => {
                        console.error("Problem adding admin user", e);
                        this.status = "needsSaving";
                        this.errorMessage = "Problem adding user";
                    })
                    .finally(() => {
                        this.getUserGroups()
                    });
            })
        },
    }
}
</script>

<style lang="scss" scoped>.player {
    &__panel {
        border: 1px solid $lightgrey;
        background-color: $white;
        padding: 20px;
        margin-bottom: 20px;

        &-details {
            flex-direction: column;

            @media (min-width: 960px) {
                flex-direction: row;
            }
        }

        &-img {
            img {
                width: 100%;
            }
        }

        &-opta {
            &-cb {
                label {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.input {
    &__textfield {
        &--basic {
            margin-top: 0;
        }
    }
}

.flex-container {
    gap: 30px;

    @media screen and (max-width: 1199px) {
        flex-direction: column;
    }
}

.column-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 1 50%;

    &:first-of-type {
        align-items: center;
    }

    .v-btn {
        align-self: center;
    }
}

.setup-key {
    font-size: 13px;
    word-break: break-all;
}

.initialise-button {
    margin-top: 20px;
    font-size: 11px;
}

.mfa-error {
    color: #ff5252;
}
</style>
