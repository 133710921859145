<template>
  <section class="main-body login">
    <v-card :class="'login__box'">
      <v-card-title :class="'login__box-gc'"><img src="../assets/gamechanger-logo-purple.svg" alt="Gamechanger"/></v-card-title>

      <v-alert :value="true"  v-if="error != null" type="error" >{{ error.message }}
        <p v-if="error.message === 'Password reset required for the user'">Please visit <router-link to='/login-reset-password'>/login-reset-password</router-link> and enter the verification code you have received at your registered email address</p>
      </v-alert>
      <v-alert :value="true"  v-if="success != null" type="success" >{{ success.message }}</v-alert>

      <v-form ref="form" v-model="valid" @submit="handleAuth">
        <template v-if="!mfaEnabled">
          <v-text-field :class="'login__box-field'" outline v-model="username" :rules="usernameRules" label="Email *"  type="email" autocomplete="email" required ></v-text-field>
          <v-text-field :class="'login__box-field'" outline v-model="pass" :rules="passRules" label="Password *" type="password"  required ></v-text-field>
        </template>
        <v-text-field v-else :class="'login__box-field'" outline v-model="mfaCode" label="6 digit code from the Authenticator App *" type="text"  required ></v-text-field>

        <v-layout row align-center justify-center mx-1>
          <router-link v-if="!mfaEnabled" to="/login-forgot-password" class="text-button">Forgotten password?</router-link>
          <v-btn
            v-else
            :class="'button'"
            depressed
            type="button"
            @click.native="mfaEnabled = false"
          >Cancel</v-btn>
          <v-btn
            color="accent"
            :disabled="!valid"
            :class="'button'"
            depressed
            type="submit"
          >
            Login
          </v-btn>
        </v-layout>

      </v-form>
    </v-card>

    <div class="footer-links">
      <router-link to="/login-set-password" class="text-button">New user?</router-link>
    </div>

  </section>
</template>


<script>
export default {
  name: "Login",
  data () {

    return {
      valid: true,
      passRules: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Remember, your password will have been at least 8 characters, contained a special character and contained both an upper case and a lower case letter"
      ],
      usernameRules: [
        v => !!v || "Email address is required",
        v => /.+@.+/.test(v) || "Email must be valid"
      ],
      username: "",
      pass: "",
      mfaCode: "",
      error: null,
      success: null,
      loading: false,
      mfaEnabled: false,
      cognitoUser: null,
      defaultSettings: null,
    }
  },

  created()  {
    this.defaultSettings = require("../config/features/"+process.env.VUE_APP_CLUB_ID+"/defaultSettings.js")
    if (this.defaultSettings.default.customPassRules) {
      const customPassRules = this.defaultSettings.default.customPassRules
      this.passRules = [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || `Remember, your password will have been at least ${customPassRules.length || 8} characters, contained a special character and ${customPassRules.lowercase ? "contained both an upper case and a lower case letter" : "an upper case letter"}`
      ]
    }
  },

  mounted () {
    console.log("Login component loaded ")
    if(window.location.href.includes("code=true")){ //if the user is referred from a partner site
      localStorage.referrer = document.referrer
      this.success = {message: "Your account has been authenticated, please login below."}
    }

  },
  methods: {
    handleAuth(e) {
      this.error = null,

      e.preventDefault()

      if (this.mfaEnabled) {
        this.mfaCheck()
      } else {
        this.login()
      }
    },

    login () {
        this.loading = true
        var email = this.username
        var password = this.pass
        this.$authenticateUser(email, password).subscribe((data) => {
          console.log("login success")
          if (data.checkMfa) {
            this.cognitoUser = data.cognitoUser
            this.mfaEnabled = true
          } else {
          // mobile app event trigger
          // if (this.$router.path !== '/') {
            this.$router.push("/")
          // }
          }
        },
        (err)=> {
            //if we get an UnkownError it is more than likely because the user has a status of 'FORCE_CHANGE_PASSWORD' in AWS Cognito
            console.log(err);
            this.error = err;
            if(err.code === "UnknownError" || err.code === "newPasswordRequired"){
              this.$router.push("/login-set-password");
            }
        });
    },

    mfaCheck() {
      this.$sendMFA(this.mfaCode, this.cognitoUser).subscribe((data) => {
        this.$router.push("/")
      },
      (err)=> {
        console.log(err);
        this.error = err;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .login {
    display: flex;
    justify-content: center;
    // height: calc(100vh - 60px);
    &__box {
      max-width: 400px;
      padding: 20px;
      width: 100%;
      &-gc {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 250px;
        }
      }
    }
  }

  .footer-links {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }
</style>
